import './scss/layouts/iko-paypal.scss';

document.addEventListener("DOMContentLoaded", e => {
  const buttonClass = '#iko-pp__donate-button'
  const settings = JSON.parse(document.querySelector(buttonClass).getAttribute('data-pp-settings'));

  PayPal.Donation.Button({
    env: settings.env,
    hosted_button_id: settings.buttonId,
    image: {
      src: settings.src,
      alt: settings.alt,
      title: settings.title,
    }
  }).render(buttonClass);
});
